:root {
  @root (fluid);
}

$purple-dark: rgb(183, 145, 198);
$purple-blue: rgb(185, 190, 250);

$primary: rgb(96, 136, 197);
$primary: darken($purple-dark, 5%);
// $secondary: rgb(229, 158, 167);
$secondary: $purple-blue;

$component-active-bg: $secondary;
$headings-margin-bottom: 1em;
$enable-negative-margins: true;

$blockquote-font-size: 1rem;

$theme-colors: (
        "primary":    $primary,
        "secondary":  $secondary,
);

@import "~bootstrap/scss/bootstrap.scss";

html, body {
  overflow-x: hidden;
}

i.fas { width: 1em; height: 1em; display: inline-block; }

#container {
  box-shadow: 0 10px 20px 0 rgba(236, 236, 236, 0.86);
}

main {
  @extend .col-sm-8;
}

aside {
  @extend .col;
}

#nav {
  //  background: url("/logo_dark.png");
  .navbar-brand {
     position: relative;
     img {
	    position: absolute;
	    max-height: 6em;
	    top: -1em;
     }

     & + .navbar-text {
	     visibility: hidden;
     }
  }

  .navbar-nav .nav-link:hover { text-decoration: underline; }
}

.post-summary {
  .heading a {
    color: $body-color;
  }
}

.page-navigation .pagination {
  @extend .justify-content-center;
}

figure.figure {
  @extend .p-4;
  @extend .bg-secondary;
  @extend .rounded;
  @extend .w-75;
  @extend .mx-auto;
  @extend .d-block;

  --bs-bg-opacity: 0.5;

  .blockquote:last-child {
    @extend .mb-0;
  }

  .blockquote-footer {
    &, & p:last-of-type {
      @extend .mb-0;
    }
  }

  a {
    --bs-link-color: #{darken($primary, 25%)};
  }
}


.img-cover {
  object-fit: cover;
}

.post-meta {
  @extend .text-muted;
  @extend .my-3;

  & > span {
    @extend .me-2;
  }
}

.post {

  section > blockquote, & > blockquote {
    position: relative;
    @extend .blockquote;
    @extend .fst-italic;
    @extend .fw-light;
    border-left: 3px solid $secondary;
    padding-left: 1em;
    margin-left: 1em;
  }

  header {


    .post-meta {
      & > span {
        @extend .me-3;
      }
    }
  }

  p {
    img {
      max-width: 100%;
    }
  }

  h2 { @extend .fs-3; }
  h3 { @extend .fs-4; }
  h4 { @extend .fs-5; }
  h5 { @extend .fs-6; }

  &::after {
    content: ' ';
    width: 100%;
    @extend .border-top;
    @extend .mb-3;
    display: block;
  }

  &:last-of-type {
    &::after { display: none; }
  }
}

.main-page.post {
  h1 { @extend .h3; }
  h2 { @extend .h4; }
}


dl.list-group dd.list-group-item {
  @extend .mb-0;

  &[data-count="1"][data-taxonomy="tags"] { display: none; }
}

.main {
  @extend .col-sm-9;
}

@include media-breakpoint-down(md) {
  .list-group-sm-collapsable {
    dd.list-group-item-action:nth-child(n+10) {
      display: none;
    }
  }
}

#search {
  button[type="submit"] {
    background: transparent url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='16' height='16' class='bi bi-search' viewBox='0 0 16 16'%3E%3Cpath d='M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001c.03.04.062.078.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1.007 1.007 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0z'%3E%3C/path%3E%3C/svg%3E") no-repeat center;
    text-indent: -999em;
    overflow: hidden;
    width: 40px;
    padding: 0;
    margin: 0;
  }
}

#instagram-feed div[data-mc-cmp-id]  {
  @include media-breakpoint-up(xs) {
    & { height: 219px !important; }
  }

  @include media-breakpoint-up(sm) {
    & { height: 280px !important; }
  }

  @include media-breakpoint-up(md) {
    & { height: 340px !important; }
  }

  @include media-breakpoint-up(lg) {
    & { height: 370px !important; }
  }

  @include media-breakpoint-up(xl) {
    & { height: 390px !important; }
  }

  @include media-breakpoint-up(xxl) {
    & { height: 390px !important; }
  }
}

.heart a.heart-symbol {
  text-decoration: none;
}

.heart {
  position: relative;
  .heart-symbol {
    display: inline-block;
    height: 1em;
    width: 1.25em;
    vertical-align: -.125em;
    background-color: $primary;
    mask-image: url('data:image/svg+xml;charset=UTF-8,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path fill="currentColor" d="M462.3 62.6C407.5 15.9 326 24.3 275.7 76.2L256 96.5l-19.7-20.3C186.1 24.3 104.5 15.9 49.7 62.6c-62.8 53.6-66.1 149.8-9.9 207.9l193.5 199.8c12.5 12.9 32.8 12.9 45.3 0l193.5-199.8c56.3-58.1 53-154.3-9.8-207.9z"></path></svg>');
    mask-repeat: no-repeat;
    mask-position: bottom;

    &.heart-animated {
      color: transparent;
      position: absolute;
      opacity: 0;
      bottom: 0;
      animation: floating-heart var(--heart-animated-duration, 10s) infinite
        cubic-bezier(var(--heart-animated-x1, 0.5), var(--heart-animated-y1, 0.5),
                      var(--heart-animated-x2, 0.5), var(--heart-animated-y2, 0.5));
    }
  }

  .heart-animation-box {
    display: inline-block;
    position: absolute;
    text-wrap: normal;
    width: var(--heart-box-width, 10rem);
    height: var(--heart-box-height, 10rem);
    left: var(--heart-box-left, 0);
    bottom: 0;
    transform: rotateY(var(--heart-box-rotation, 0));
  }
}

@keyframes floating-heart {
  0% {
    opacity: 0;
    bottom: 0;
    left: 50%;
  }
  10% {
    opacity: 1;
    bottom: 20%;
    left: 90%;
  }
  20% {
    bottom: 40%;
    left: 10%;
  }
  30% {
    bottom: 60%;
    left: 30%;
  }
  40% {
    opacity: 1;
    bottom: 80%;
    left: 50%;
  }
  48% {
    opacity: 0;
    bottom: 100%;
    left: 60%;
  }
  100% {
    opacity: 0;
    bottom: 100%;
    left: 90%;
  }
}

.hero-box {
  @extend .border;
  @extend .border-primary;
  @extend .border-1;
  @extend .p-4;
  @extend .bg-white;
}

.hero-container {
  z-index: 0;
  @extend .overflow-visible;
}
